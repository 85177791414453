import { TextField, Tooltip, Typography } from "@krakentech/coral";
import { IconQuestion } from "@krakentech/icons";
import { Box } from "@mui/material";
import useTranslation from "next-translate/useTranslation";
import { useFormContext } from "react-hook-form";

export type EmailAddressFormPartial = {
  readonly email: string;
};

export const EmailQuote = () => {
  const { t } = useTranslation("enrollment/quote");
  const {
    register,
    formState: { errors },
  } = useFormContext<EmailAddressFormPartial>();
  return (
    <>
      <Box width="100%" mt={6} mb={2}>
        <Typography
          variant="h5"
          textAlign="left"
          attributes={{
            "data-cy": "email-quote-prompt",
          }}
        >
          {t("sendToInbox")}
        </Typography>
      </Box>
      <Box width="100%" mb={2}>
        <Typography
          variant="body1"
          textAlign="left"
          attributes={{
            "data-cy": "email-quote-to-yourself-text",
          }}
        >
          {t("emailToYourself")}
        </Typography>
      </Box>
      <Box width="100%">
        <Box maxWidth={360}>
          <TextField
            label={t("emailInput")}
            inputProps={{
              name: register("email").name,
            }}
            {...register("email")}
            endIcon={
              <Tooltip
                title={t("weWillEmailNoSpam")}
                placement="top"
                zIndex={1}
              >
                <IconQuestion />
              </Tooltip>
            }
            error={Boolean(errors.email?.message)}
            errorMessage={errors.email?.message}
          />
        </Box>
      </Box>
    </>
  );
};
