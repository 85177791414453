import { Checkbox, Typography } from "@krakentech/coral";
import { Box, Grid } from "@mui/material";
import useTranslation from "next-translate/useTranslation";
import { useFormContext } from "react-hook-form";

export type SmartDevicesChexboxesFormPartial = {
  smartThermostat: boolean;
  electricVehicle: boolean;
  solarPanels: boolean;
  storageBattery: boolean;
};

export const SmartDevicesCheckboxes = () => {
  const { t } = useTranslation("enrollment/formFields");
  const { register, watch } =
    useFormContext<SmartDevicesChexboxesFormPartial>();
  return (
    <>
      <Box width="100%" mt={3} mb={3}>
        <Typography
          variant="h5"
          textAlign="left"
          attributes={{
            "data-cy": "smart-devices-prompt",
          }}
        >
          {t("smartDevicesPrompt")}
        </Typography>
      </Box>
      <Grid container spacing={1.5}>
        <Grid item xs={12} md={7} data-cy="smart-devices-therm">
          <Checkbox
            label={t("smartDeviceLabelThermostat")}
            checked={watch().smartThermostat}
            inputProps={{
              ...register("smartThermostat"),
            }}
          />
        </Grid>
        <Grid item xs={12} md={5} data-cy="smart-devices-ev">
          <Checkbox
            label={t("smartDeviceLabelElectricVehicle")}
            checked={watch().electricVehicle}
            inputProps={{
              ...register("electricVehicle"),
            }}
          />
        </Grid>
        <Grid item xs={12} md={7} data-cy="smart-devices-solar">
          <Checkbox
            label={t("smartDeviceLabelSolarPanels")}
            checked={watch().solarPanels}
            inputProps={{
              ...register("solarPanels"),
            }}
          />
        </Grid>
        <Grid item xs={12} md={5} data-cy="smart-devices-battery">
          <Checkbox
            label={t("smartDeviceLabelBattery")}
            checked={watch().storageBattery}
            inputProps={{
              ...register("storageBattery"),
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};
