export const batteryModels = [
  { value: "Enphase", label: "Enphase" },
  { value: "SolarEdge", label: "SolarEdge" },
];

export const rivianEvOptions = [
  { value: "R1T", label: "R1T" },
  { value: "R1S", label: "R1S" },
  { value: "R2", label: "R2" },
  {
    value: "R3",
    label: "R3",
  },
];

export const thermostatBrands = [
  { value: "Amazon", label: "Amazon" },
  { value: "Ecobee", label: "Ecobee" },
  { value: "Sensi", label: "Sensi" },
  {
    value: "Honeywell",
    label: "Honeywell",
  },
];

export const thermostatModels: {
  [key: string]: { value: string; label: string }[];
} = {
  Honeywell: [
    {
      value: "HoneywellT5PlusPro",
      label: "Honeywell T5+ Pro Smart Thermostat",
    },
    { value: "HoneywellT6Pro", label: "Honeywell T6 Pro Smart Thermostat" },
    { value: "HoneywellT9Pro", label: "Honeywell T9 Pro Smart Thermostat" },
    { value: "HoneywellT10Pro", label: "Honeywell T10 Pro Smart Thermostat" },
    { value: "HoneywellRound", label: "Honeywell Round Smart Thermostat" },
    {
      value: "HoneywellWiFiSmartColor",
      label: "Honeywell Wi-Fi Smart Color Thermostat",
    },
    {
      value: "HoneywellWiFi9000",
      label: "Honeywell Wi-Fi 9000 7-Day Programmable Thermostat",
    },
    {
      value: "HoneywellWiFi7Day",
      label: "Honeywell Wi-Fi 7-Day Programmable Thermostat",
    },
    { value: "Honeywell9000Smart", label: "Honeywell 9000 Smart Thermostat" },
    {
      value: "Honeywell7DayProgrammable",
      label: "Honeywell 7-Day Programmable Smart Thermostat",
    },
    {
      value: "HoneywellVisionPro8000",
      label: "Honeywell VisionPro 8000 Smart Thermostat",
    },
    { value: "HoneywellPrestige", label: "Honeywell Prestige Thermostat" },
    {
      value: "HoneywellVisionPro8000RedLINK",
      label: "Honeywell VisionPro 8000 RedLINK Thermostat",
    },
  ],
};

export const evBrands = [
  { value: "Audi", label: "Audi" },
  { value: "Mini", label: "Mini" },
  { value: "Porsche", label: "Porsche" },
  { value: "Tesla", label: "Tesla" },
  { value: "Volkswagen", label: "Volkswagen" },
];

export const evModels: {
  [key: string]: { value: string; label: string }[];
} = {
  Audi: [
    {
      value: "Q4 e-tron",
      label: "Q4 e-tron",
    },
    { value: "e-tron Sportback", label: "e-tron Sportback" },
    { value: "e-tron", label: "e-tron" },
    { value: "e-tron GT", label: "e-tron GT" },
    { value: "Q4 Sportback e-tron", label: "Q4 Sportback e-tron" },
    { value: "Q7 e-tron", label: "Q7 e-tron" },
    { value: "Q7", label: "Q7" },
    { value: "A3 Sportback e-tron", label: "A3 Sportback e-tron" },
    { value: "A3 Sportback", label: "A3 Sportback" },
    { value: "A6 Saloon", label: "A6 Saloon" },
    { value: "A7 Sportback", label: "A7 Sportback" },
    { value: "Q3", label: "Q3" },
    { value: "e-tron S Sportback", label: "e-tron S Sportback" },
    { value: "e-tron S", label: "e-tron S" },
  ],
  BMW: [
    { value: "i4", label: "i4" },
    { value: "i3", label: "i3" },
    { value: "iX", label: "iX" },
    { value: "i3s", label: "i3s" },
    { value: "iX3", label: "iX3" },
    { value: "X2", label: "X2" },
    { value: "530e Saloon", label: "530e Saloon" },
    { value: "i8", label: "i8" },
    { value: "740e", label: "740e" },
    { value: "530e", label: "530e" },
    { value: "225xe", label: "225xe" },
    { value: "330e Touring", label: "330e Touring" },
    { value: "530e Touring", label: "530e Touring" },
    { value: "X3", label: "X3" },
    { value: "745e", label: "745e" },
    { value: "330e Saloon", label: "330e Saloon" },
    { value: "545e Saloon", label: "545e Saloon" },
    { value: "330e", label: "330e" },
    { value: "230e", label: "230e" },
    { value: "225e", label: "225e" },
  ],
  Chevy: [{ value: "Bolt", label: "Bolt" }],
  Ford: [{ value: "Mustang Mach-E", label: "Mustang Mach-E" }],
  Mini: [
    { value: "Countryman", label: "Countryman" },
    { value: "Electric", label: "Electric" },
  ],
  Porsche: [
    { value: "Taycan", label: "Taycan" },
    { value: "Cayenne", label: "Cayenne" },
    { value: "Cayenne Coupe", label: "Cayenne Coupe" },
  ],
  Tesla: [
    { value: "Model Y", label: "Model Y" },
    { value: "Cybertruck", label: "Cybertruck" },
    { value: "Model 3", label: "Model 3" },
    { value: "Model S", label: "Model S" },
    { value: "Model X", label: "Model X" },
    { value: "Model Y", label: "Model Y" },
  ],
  Volkswagen: [
    { value: "ID.3", label: "ID.3" },
    { value: "ID.4", label: "ID.4" },
    { value: "ID.5", label: "ID.5" },
    { value: "ID. Buzz Cargo", label: "ID. Buzz Cargo" },
    { value: "ID. Buzz", label: "ID. Buzz" },
  ],
};
