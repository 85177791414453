import { atom, useAtom } from "jotai";
import type {
  Country,
  IPResponseBody,
  ISOCountryCodes,
} from "./LocalizationTypes";

export type Location = {
  ipAddress: string | null;
  clientCountry: Country | null;
  responseCache: IPResponseBody | null;
  userRequestedToHideDialog: boolean;
  userRequestedRedirect: boolean;
  regionToRedirectTo: ISOCountryCodes | null;
};

export const LocationAtomDefaults: Location = {
  ipAddress: null,
  clientCountry: null,
  responseCache: null,
  userRequestedToHideDialog: false,
  userRequestedRedirect: false,
  regionToRedirectTo: null,
};

export const locationAtom = atom<Location>(LocationAtomDefaults);

/**
 * Hook that returns the current location state
 */
export const useLocation = () => useAtom(locationAtom);
