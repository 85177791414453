import { useQueryProperties } from "@core/apiRequests";
import { handleError } from "@core/error";
import { getPlan, usePortalAccountNumber } from "@core/portalUtils";
import { StripeElements } from "@core/stripe";
import { useDeviceBreakpoint } from "@core/utils/useDeviceBreakpoint";
import { Button, Card, Typography, useCoralTheme } from "@krakentech/coral";
import { IconEdit } from "@krakentech/icons";
import { Stack } from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";
import { useBooleanState } from "react-use-object-state";
import { AutopaySettings } from "./AutopaySettings";
import { PaymentAchSettings } from "./PaymentAchSettings";
import { PaymentCreditCardSettings } from "./PaymentCreditCardSettings";
import { PaymentPrepayTopoffSettings } from "./PaymentPrepayTopoffSettings";

type PaymentMethodSettingsCardProps = {
  autopaySettings?: boolean;
};

export const PaymentMethodSettingsCard = ({
  autopaySettings = true,
}: PaymentMethodSettingsCardProps) => {
  const { t } = useTranslation("payments/payment-methods");
  const accountNumber = usePortalAccountNumber();
  const theme = useCoralTheme();
  const { isMobile } = useDeviceBreakpoint();
  const editing = useBooleanState(false);
  const saveTrigger = useBooleanState(false);
  const cancelButtonClicked = useBooleanState(false);
  const addCreditCardTrigger = useBooleanState(false);
  const addBankAccountTrigger = useBooleanState(false);
  const deleteCreditCardConfirmation = useBooleanState(false);
  const deleteAchConfirmation = useBooleanState(false);
  const preEnrolledInAutopay = useBooleanState(false);
  const isReplacingAutopay = useBooleanState(false);

  const { data } = useQueryProperties({
    variables: {
      accountNumber,
      includeSubscriptionFees: true,
    },
    onError: handleError,
  });

  const { isPrepay } = getPlan(data);

  const renderSettings = () => (
    <>
      {autopaySettings &&
        !addCreditCardTrigger.state &&
        !addBankAccountTrigger.state &&
        !deleteCreditCardConfirmation.state &&
        !deleteAchConfirmation.state && (
          <AutopaySettings
            editing={editing}
            saveTrigger={saveTrigger}
            resetSaveTrigger={() => saveTrigger.setFalse()}
            cancelButtonClicked={cancelButtonClicked}
          />
        )}
      {isPrepay &&
        !addCreditCardTrigger.state &&
        !addBankAccountTrigger.state &&
        !deleteCreditCardConfirmation.state && (
          <PaymentPrepayTopoffSettings
            editing={editing}
            saveTrigger={saveTrigger}
            resetSaveTrigger={() => saveTrigger.setFalse()}
          />
        )}
      {!addCreditCardTrigger.state && !addBankAccountTrigger.state && (
        <Stack mt={3}>
          <Typography variant="caption" color="tertiary">
            {t("savedPaymentMethods")}
          </Typography>
        </Stack>
      )}
      <StripeElements>
        {!addBankAccountTrigger.state && !deleteAchConfirmation.state ? (
          <PaymentCreditCardSettings
            accountNumber={accountNumber}
            editing={editing}
            addCreditCardTrigger={addCreditCardTrigger}
            addBankAccountTrigger={addBankAccountTrigger}
            deleteCreditCardConfirmation={deleteCreditCardConfirmation}
            preEnrolledInAutopay={preEnrolledInAutopay}
            isReplacingAutopay={isReplacingAutopay}
          />
        ) : null}
        {!addCreditCardTrigger.state && !deleteCreditCardConfirmation.state ? (
          <PaymentAchSettings
            accountNumber={accountNumber}
            editing={editing}
            addBankAccountTrigger={addBankAccountTrigger}
            addCreditCardTrigger={addCreditCardTrigger}
            deleteAchConfirmation={deleteAchConfirmation}
            preEnrolledInAutopay={preEnrolledInAutopay}
            isReplacingAutopay={isReplacingAutopay}
          />
        ) : null}
      </StripeElements>
    </>
  );

  const renderActionButtons = () => (
    <Stack my={3} spacing={2} direction={isMobile ? "column" : "row"}>
      <Button
        fullWidth
        variant="outlined"
        onClick={() => {
          cancelButtonClicked.setTrue();
          editing.setFalse();
        }}
      >
        {t("cancel")}
      </Button>
      <Button
        fullWidth
        onClick={() => {
          saveTrigger.setTrue();
        }}
      >
        {t("saveChanges")}
      </Button>
    </Stack>
  );

  return (
    <Card
      borderless
      fullWidth
      padding="small"
      attributes={{
        "data-cy": "settings-payment-card",
      }}
    >
      <Stack px={2}>
        <Stack display="flex" direction="row" justifyContent="space-between">
          <Typography variant="h5">{t("paymentSettings")}</Typography>
          {!editing.state && (
            <Button onClick={editing.setTrue} variant="text" size="small">
              <IconEdit size={24} color={theme.color.tertiary.main} />
            </Button>
          )}
        </Stack>
      </Stack>
      <Stack>
        {renderSettings()}
        {editing.state &&
          !addCreditCardTrigger.state &&
          !addBankAccountTrigger.state &&
          !deleteCreditCardConfirmation.state &&
          !deleteAchConfirmation.state &&
          renderActionButtons()}
      </Stack>
    </Card>
  );
};
