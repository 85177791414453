import { ProductListType } from "@core/krakenHelpers";
import { LegalLinks } from "@core/legal";
import {
  Accordion,
  Button,
  Card,
  Container,
  Drawer,
  Link,
  Stack,
  Typography,
} from "@krakentech/coral";
import { Box } from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { FC, ReactNode, useEffect } from "react";
import {
  MultipleTdspAlert,
  useCustomerIsRivian,
  useEnrollment,
  useOctopusProduct,
} from "../../enrollment";
import {
  EnrollmentProductDescription,
  OctopusProductId,
  OctopusProductTranslation,
  ProductCardBullets,
  ProductCardMonthlyEstimate,
  ProductCardRateBreakdown,
  ProductCardTitle,
  isPrepayProduct,
} from "../../product";

export type EnrollmentProductDrawerProps = {
  title: ReactNode;
  productInformationDialogOpen: boolean;
  onClose: VoidFunction;
  withThermostat?: boolean;
  withEV?: boolean;
  renewal: boolean;
  productId: OctopusProductId;
  serviceProviders?: ProductListType[0]["serviceProviders"];
  hasTerminationFee?: boolean;
  minPrice?: number | string | null;
  rate?: ReactNode;
  lockedInDuration?: number;
  disclaimer?: {
    checkbox?: boolean;
    paragraph?: ReactNode;
  };
  lang: OctopusProductTranslation;
  solarBuyback?: boolean;
  "data-cy"?: string;
  renewalStartDate?: Date | undefined;
  formattedRenewalStartDate?: string;
  formattedRenewalEndDate?: string;
  renewalQuoteCode?: string;
  onClick?: VoidFunction;
  usageEstimate?: number | string;
};

export const EnrollmentProductDrawer: FC<EnrollmentProductDrawerProps> = ({
  productId,
  withThermostat = false,
  withEV = false,
  title,
  renewal = false,
  productInformationDialogOpen,
  minPrice,
  onClick,
  onClose,
  serviceProviders = [],
}) => {
  const octopusProduct = useOctopusProduct(productId);
  const { zipCodeHasMultipleTdspsOrLoadZones, customerHasEnteredAddressEarly } =
    useEnrollment();
  const { t } = useTranslation("product/productCard");
  const { t: lang } = useTranslation("enrollment/plans");
  const router = useRouter();
  const isPrepay = isPrepayProduct(productId);
  const power12Product = productId === OctopusProductId.OCTO_BATTERY_12M;
  const customerIsRivian = useCustomerIsRivian();

  useEffect(() => {
    if (renewal) {
      document.body.style.overflow = "";
    }
  }, [onClose]);

  return (
    <Drawer
      open={productInformationDialogOpen}
      onClose={onClose}
      attributes={{
        "data-cy": "enrollment-product-drawer",
      }}
    >
      <Container marginX="sm" marginY="xxxl">
        {zipCodeHasMultipleTdspsOrLoadZones &&
          !customerHasEnteredAddressEarly && (
            <Box display="flex" justifyContent="center" my={2}>
              <MultipleTdspAlert
                onClick={() =>
                  router.push({
                    pathname: "/join/enrollment/quote",
                    query: { referrer: "zipcode", enterAddressEarly: true },
                  })
                }
              />
            </Box>
          )}
        <ProductCardTitle
          withThermostat={withThermostat}
          withEV={withEV}
          productId={productId}
        >
          {title}
        </ProductCardTitle>
        <ProductCardMonthlyEstimate
          minPrice={minPrice}
          offPeakPrice={octopusProduct?.prices.offPeakPrice}
        />
        <ProductCardRateBreakdown
          align="center"
          minPrice={minPrice}
          peakPrice={octopusProduct?.prices.peakPrice}
        />
        <Stack direction="vertical">
          <Container marginTop="md">
            <Accordion
              title={t("howAreMonthlyCostsCalculated")}
              attributes={{
                "data-cy": "monthly-cost-calc-accordion",
              }}
            >
              <Stack direction="vertical">
                <Typography> {t("whatWeUseToCalculate")}</Typography>
                <Stack>
                  <Typography>1.</Typography>
                  <Typography>{t("energyRate")}</Typography>
                </Stack>
                <Stack>
                  <Typography>2.</Typography>
                  <Typography>{t("tdspDeliveryCharges")}</Typography>
                </Stack>
                <Stack>
                  <Typography>3.</Typography>
                  <Typography>{t("subscriptionFee")}</Typography>
                </Stack>
              </Stack>
            </Accordion>
          </Container>
          {power12Product && (
            <Card theme="midLight" borderless>
              <Stack direction="vertical">
                <Typography>
                  <strong>{t("aboutIO")}</strong>
                </Typography>
                <Typography>
                  {t("ioIsOurInnovativeTechnologyBattery")}
                </Typography>
                <Typography>
                  <strong>{t("youCanEarnFifteenDollarsEveryMonth")}</strong>
                </Typography>
              </Stack>
            </Card>
          )}
          {(withThermostat || withEV) && (
            <Card
              theme="midLight"
              borderless
              attributes={{
                "data-cy": "about-io-card",
              }}
            >
              <EnrollmentProductDescription withThermostat={withThermostat} />
            </Card>
          )}
          <Card
            theme="midLight"
            borderless
            attributes={{
              "data-cy": "plan-details",
            }}
          >
            <Stack direction="vertical" gap="md">
              <Typography>
                <strong>{t("planDetails")}</strong>
              </Typography>
              {!withThermostat &&
                !withEV &&
                !customerIsRivian &&
                !power12Product && (
                  <Stack
                    gap="sm"
                    direction="vertical"
                    attributes={{
                      "data-cy": "about-octo12-text",
                    }}
                  >
                    <Typography>{t("octo12LongTermValue")}</Typography>
                    <Typography>{t("octo12CanUpgrade")}</Typography>
                  </Stack>
                )}
              <ProductCardBullets
                isPrepay={isPrepay}
                withEV={withEV}
                withThermostat={withThermostat}
                productId={productId}
              />
            </Stack>
          </Card>
          <Card
            theme="midLight"
            borderless
            attributes={{
              "data-cy": "plan-documents",
            }}
          >
            <Stack direction="vertical" gap="md">
              <Typography>
                <strong>{t("planDocuments")}</strong>
              </Typography>
              <LegalLinks
                serviceProviders={serviceProviders}
                disclosures={{ allDisclosures: true }}
                color="light"
                icon={true}
                ioAgreement={
                  withEV || withThermostat || power12Product ? true : false
                }
              />
            </Stack>
          </Card>
          <Button fullWidth onClick={onClick} data-cy="select-product-button">
            {t("signMeUp")}
          </Button>
          <Stack direction="vertical" alignItems="center">
            <Link
              href="https://octo.ps/3tL4KkB"
              target="_blank"
              rel="noopener noreferrer"
            >
              {lang("waitlistLink")}
            </Link>
          </Stack>
        </Stack>
      </Container>
    </Drawer>
  );
};
