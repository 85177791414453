import {
  IconAutoPay,
  IconBattery,
  IconGraph,
  IconLeaf,
  IconNoExitFees,
  IconTime,
  IconUSD,
} from "@krakentech/icons";
import { Stack, Typography } from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";
import { FC, ReactNode } from "react";

//TODO: Add data-cy prop
export type BulletProps = { mt?: number };

type ProductBulletProps = BulletProps & {
  icon: ReactNode;
  label: string;
};

//Simple util to return the translation
const returnTranslation = (text: string) => {
  const { t } = useTranslation("product/productCard");
  return t(text);
};

//Base component to create a bullet
const ProductBullet: FC<ProductBulletProps> = ({ mt, icon, label }) => {
  return (
    <Stack direction="row" alignItems="center" mt={mt}>
      {icon}
      <Typography variant="body2" ml={3}>
        {label}
      </Typography>
    </Stack>
  );
};

//Product Bullets
export const AllPaperless = (props: BulletProps) => {
  return (
    <ProductBullet
      icon={<IconLeaf size={16} />}
      label={returnTranslation("allPaperless")}
      {...props}
    />
  );
};

export const AutopayRequired = (props: BulletProps) => {
  return (
    <ProductBullet
      icon={<IconAutoPay size={16} />}
      label={returnTranslation("autopay")}
      {...props}
    />
  );
};

export const BatterySavings = (props: BulletProps) => {
  return (
    <ProductBullet
      icon={<IconUSD size={16} />}
      label={returnTranslation("threeDollarsInSavings")}
      {...props}
    />
  );
};

export const BillCredit = (props: BulletProps) => {
  return (
    <ProductBullet
      icon={<IconUSD size={16} />}
      label={returnTranslation("billCredit")}
      {...props}
    />
  );
};

export const ChangesMonthly = (props: BulletProps) => {
  return (
    <ProductBullet
      icon={<IconGraph size={16} />}
      label={returnTranslation("rateChangesMonthly")}
      {...props}
    />
  );
};

export const EarlyTerminationFee = (props: BulletProps) => {
  return (
    <ProductBullet
      icon={<IconUSD size={16} />}
      label={returnTranslation("earlyTerminationFee")}
      {...props}
    />
  );
};

export const EnphaseAndSolarEdgeBatteries = (props: BulletProps) => {
  return (
    <ProductBullet
      icon={<IconBattery size={16} />}
      label={returnTranslation("worksWithTheseBatteries")}
      {...props}
    />
  );
};

export const NoBaitAndSwitch = (props: BulletProps) => {
  return (
    <ProductBullet
      icon={<IconNoExitFees size={16} />}
      label={returnTranslation("baitAndSwitch")}
      {...props}
    />
  );
};

export const OctoGridboost = (props: BulletProps) => {
  return (
    <ProductBullet
      icon={<IconBattery size={16} />}
      label={returnTranslation("optimizationWithOctoGridboost")}
      {...props}
    />
  );
};

export const PeakAndOffPeakHours = (props: BulletProps) => {
  return (
    <ProductBullet
      icon={<IconTime size={24} />}
      label={returnTranslation("peakVsOffPeakHours")}
      {...props}
    />
  );
};
