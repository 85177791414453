import {
  useCustomerIsPTC,
  useCustomerIsRivian,
  useSelectedEnrollmentProduct,
  useSelectedEnrollmentProductIsPrepay,
  useTotalPaymentDue,
} from "@core/enrollment";
import { ProductListType } from "@core/krakenHelpers";
import { LegalLinks } from "@core/legal";
import { Maybe } from "@core/types";
import { useDeviceBreakpoint } from "@core/utils/useDeviceBreakpoint";
import { Accordion, Card, Tooltip } from "@krakentech/coral";
import { Box, CardContent, Stack, Typography } from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { FC } from "react";
import { EnrollmentProductDescription } from "../EnrollmentProductDescription";
import { OctopusProductTitle } from "../OctopusProducts";
import {
  ProductCardBullets,
  ProductCardMonthlyEstimate,
  ProductCardRateBreakdown,
} from "../ProductCard";
import { OctopusProductId } from "../octopusProductConstants";

type ProductSelectedCardProps = {
  displayName?: string | null;
  withEV?: Maybe<boolean>;
  withThermostat?: Maybe<boolean>;
  quoteHasChanged: boolean;
  serviceProviders: ProductListType[0]["serviceProviders"];
};

export const ProductSelectedCard: FC<ProductSelectedCardProps> = ({
  displayName,
  withEV,
  withThermostat,
  quoteHasChanged,
  serviceProviders = [],
}) => {
  const { t } = useTranslation("product/productCard");
  const { isMobile } = useDeviceBreakpoint();
  const lang = useRouter().locale;
  const selectedProduct = useSelectedEnrollmentProduct();
  const customerIsPTC = useCustomerIsPTC();
  const power12Product =
    selectedProduct?.productID === OctopusProductId.OCTO_BATTERY_12M;
  const customerIsRivian = useCustomerIsRivian();
  const isPrepay = useSelectedEnrollmentProductIsPrepay();
  const totalDue = useTotalPaymentDue();

  const totalRate =
    (withEV && !customerIsPTC && selectedProduct?.prices.discountEVPrice) ||
    (withThermostat &&
      !customerIsPTC &&
      selectedProduct?.prices.discountThermostatPrice) ||
    selectedProduct?.prices.basePrice;

  return (
    <Card
      borderless
      fullWidth
      padding="small"
      attributes={{
        "data-cy": "product-checkout-card",
      }}
    >
      <CardContent>
        <Typography variant="h4" data-cy="product-header" mb={2}>
          {t("yourPlan")}
        </Typography>
        <Stack direction={isMobile ? "column" : "row"}>
          <Stack
            width={isMobile ? "100%" : "50%"}
            pr={isMobile ? undefined : 2}
          >
            <OctopusProductTitle
              bold={power12Product ? "Power 12" : displayName}
              withEV={withEV}
              withThermostat={withThermostat}
              productVariant={isMobile ? "h5" : "h4"}
              subtitleVariant={isMobile ? "caption" : "body2"}
              minHeight="unset"
              height={isMobile ? 60 : "auto"}
              productId={selectedProduct?.productID}
            />
          </Stack>
          <Stack width={isMobile ? "100%" : "50%"}>
            <ProductCardMonthlyEstimate
              minPrice={totalRate}
              stackProps={{
                alignItems: "left",
                justifyContent: "left",
              }}
              monthlyRateProps={{
                mt: withThermostat ? 0.25 : 1,
                mb: 1,
                align: "left",
                variant: isMobile ? "body2" : "h4",
                height: 20,
              }}
              monthlyEstimateTextProps={{
                align: "left",
                variant: "caption",
                height: 14,
              }}
              offPeakPrice={selectedProduct?.prices.offPeakPrice}
            />
            <ProductCardRateBreakdown
              minPrice={totalRate}
              variant={"caption"}
              peakPrice={selectedProduct?.prices.peakPrice}
            />
            {quoteHasChanged && (
              <Stack direction="row" spacing={1}>
                <Typography variant="body2" fontWeight="bold">
                  {t("whyIsQuoteDifferent")}
                </Typography>
                <Tooltip title={t("youLiveInMultipleLZArea")} />
              </Stack>
            )}
          </Stack>
        </Stack>
        <Stack mt={1}>
          <Accordion
            theme="mid"
            title={t("planDetails")}
            attributes={{
              "data-cy": "monthly-cost-calc-accordion",
            }}
          >
            {!withThermostat &&
              !withEV &&
              !customerIsRivian &&
              !power12Product && (
                <Stack data-cy="about-octo12-text" mb={2}>
                  <Typography>{t("octo12LongTermValue")}</Typography>
                  <Typography mt={2}>{t("octo12CanUpgrade")}</Typography>
                </Stack>
              )}
            <Stack
              sx={{
                flexDirection: "column",
                flexWrap: "wrap",
                overflow: "auto",
              }}
            >
              <ProductCardBullets
                isPrepay={isPrepay}
                withEV={withEV}
                withThermostat={withThermostat}
                productId={selectedProduct?.productID}
              />
            </Stack>
            {(withThermostat || withEV) && (
              <Stack mt={2}>
                <EnrollmentProductDescription withThermostat={withThermostat} />
              </Stack>
            )}
            <Stack spacing={2} mt={4}>
              <Typography>
                <strong>{t("planDocuments")}</strong>
              </Typography>
              <LegalLinks
                serviceProviders={serviceProviders}
                disclosures={{ allDisclosures: true }}
                color="light"
                icon={true}
                ioAgreement={
                  withEV || withThermostat || power12Product ? true : false
                }
              />
            </Stack>
          </Accordion>
        </Stack>
        {(withThermostat || withEV) && (
          <Box>
            <Typography
              data-cy="check-your-inbox-text"
              mt={2}
              variant={lang === "es" ? "caption" : "body2"}
            >
              {withThermostat && t("checkYourInboxThermostat")}
              {withEV && t("checkYourInboxEV")}
            </Typography>
          </Box>
        )}
        <Stack direction="row" mt={2}>
          <Stack width={isMobile ? "65%" : "50%"}>
            <Typography>{t("totalDueToday")}</Typography>
          </Stack>
          <Stack width={isMobile ? "35%" : "50%"}>
            <Typography fontWeight="bold" data-cy="total-due-today-amount">
              ${totalDue}
            </Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};
