
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import { useSyncEnrollmentStateWithBrowserStorage } from "@core/apiRequests/enrollment/useEnrollmentV2";
import { isDevEnv } from "@core/env";
import { useCheckLocation } from "@core/localization";
import { EmotionCache } from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { CoralThemeProvider } from "@krakentech/coral";
import { CoralStoryblokProvider } from "@krakentech/coral-storyblok";
import { ApolloProvider } from "@octopus-energy/apollo-client";
import {
  emotionCache as coralEmotionCache,
  LocalizationProvider,
  octopusDarkTheme,
  ThemeProvider,
} from "@octopus-energy/coral-mui";
import { Components } from "@octopus-energy/storyblok-cms";
import { apiPlugin, StoryblokComponent, storyblokInit } from "@storyblok/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { AppProps } from "next/dist/shared/lib/router/router";
import Link from "next/link";
import { FC, ReactNode, useEffect, useState } from "react";
import { Provider as JotaiProvider } from "jotai";
import { useRefreshAuthToken } from "../src/auth";
import { useCheckGlobalParametersState } from "../src/enrollment";
import { EnrollmentProvider } from "../src/enrollment/enrollmentContext";
import { ErrorBoundary } from "../src/error";
import { useGoogleTrackPageView } from "../src/googleAnalytics";
import { AppIntlProvider } from "../src/internationalization";
import {
  SnackbarNotificationProvider,
  useSnackbarNotification,
} from "../src/molecules";

/**
 * Displays warning if app is running in dev env, but hitting prod API.
 */
const ProdApiEndpointWarning = () => {
  const [notification] = useSnackbarNotification();

  useEffect(() => {
    if (
      isDevEnv() &&
      process.env.NEXT_PUBLIC_KRAKEN_GRAPHQL_URI?.includes("energy")
    ) {
      notification.warning("Warning: Hitting prod API in dev mode", {
        autoHideDuration: 10_000,
      });
    }
  }, []);

  return null;
};

type AppPropsExtended = AppProps & {
  emotionCache: EmotionCache;
};

storyblokInit({
  accessToken: process.env.NEXT_PREVIEW_MODE_TOKEN,
  use: [apiPlugin],
  components: Components,
});

const AppProviders: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [queryClient] = useState(() => new QueryClient());

  return (
    <JotaiProvider>
      <ApolloProvider>
        <QueryClientProvider client={queryClient}>
          <AppIntlProvider>
            <EnrollmentProvider>
              <ThemeProvider theme={octopusDarkTheme}>
                <CoralThemeProvider
                  config={{ remSize: 0.625, LinkComponent: Link }}
                >
                  <CoralStoryblokProvider
                    StoryblokComponent={StoryblokComponent}
                  >
                    <LocalizationProvider>
                      <SnackbarNotificationProvider>
                        <ErrorBoundary>
                          <ProdApiEndpointWarning />
                          {children}
                        </ErrorBoundary>
                      </SnackbarNotificationProvider>
                    </LocalizationProvider>
                  </CoralStoryblokProvider>
                </CoralThemeProvider>
              </ThemeProvider>
            </EnrollmentProvider>
          </AppIntlProvider>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </ApolloProvider>
    </JotaiProvider>
  );
};

const App = ({ Component, pageProps }: AppProps) => {
  useGoogleTrackPageView();
  useCheckLocation();
  // keep at root of app to trigger an auth refresh before the JWT expires
  useRefreshAuthToken();

  useSyncEnrollmentStateWithBrowserStorage();

  useCheckGlobalParametersState();

  return <Component {...pageProps} />;
};

const AppContainer: FC<AppPropsExtended> = ({
  emotionCache = coralEmotionCache,
  ...props
}) => {
  return (
    <CacheProvider value={emotionCache}>
      <AppProviders>
        <App {...props} />
      </AppProviders>
    </CacheProvider>
  );
};

const __Page_Next_Translate__ = AppContainer;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  