import { Maybe } from "@core/types";
import { atom, useAtom } from "jotai";

/**
 * Sets up default global parameters jotai atom state.
 */

export type GlobalParametersStateType = {
  navigatorState: boolean;
  subDomain?: string;
  skipPayment?: boolean;
  audio_recording_id?: Maybe<string>;
};

export const globalParametersAtom = atom<GlobalParametersStateType>({
  navigatorState: false,
  skipPayment: false,
});

export const useGlobalParameterState = () => useAtom(globalParametersAtom);
