import { atom, useAtom } from "jotai";
import { SbBlokData } from "@storyblok/react";

type GlobalOutageAlert = {
  showAlert: boolean;
  alertContent?: (SbBlokData | undefined)[];
};

export const globalOutageAlertAtom = atom<GlobalOutageAlert>({
  showAlert: false,
  alertContent: undefined,
});

/**
 * Hook that returns the current global outage alert state
 */
export const useGlobalOutageAlert = () => useAtom(globalOutageAlertAtom);
