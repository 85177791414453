import { useRouter } from "next/router";
import { storeGlobalParametersSessionData } from ".";
import {
  obtainNewKrakenToken,
  useIsValidSubdomainFunction,
} from "../../apiRequests";
import { handleError } from "../../error";
import { useSnackbarNotification } from "../../molecules";
import { globalParametersAtom } from "./useGlobalParametersState";
import { useAtom } from "jotai";

/**
 * Sets navigator states in global parameters jotai atom, you must pass a valid affiliate subdomain string else it
 * won't do anything.
 *
 * @returns setState()
 * @example
 * const setNavigator = useSetNavigatorState();
 * setNavigator("SomeNavigatorDomainString");
 */

export const useSetNavigatorState = () => {
  const [existingParameters, setGlobalParametersState] =
    useAtom(globalParametersAtom);

  const [notification] = useSnackbarNotification();
  const checkSubdomain = useIsValidSubdomainFunction();
  const router = useRouter();
  const audioRecordingId = router.query.audio_recording_id as
    | string
    | undefined;

  const setState = async (subDomain: string | undefined) => {
    try {
      if (!subDomain) throw new Error("No subdomain entered.");

      const { token } = await obtainNewKrakenToken();

      const {
        data: { isValidSubdomain },
      } = await checkSubdomain({
        context: {
          headers: {
            Authorization: token,
          },
        },
        variables: {
          subdomain: subDomain,
        },
      });

      if (!isValidSubdomain?.isValid) {
        throw new Error("Invalid subdomain.");
      }

      notification.success("Entered navigator mode.");

      const navigatorData = {
        ...existingParameters,
        navigatorState: true,
        subDomain,
        skipPayment: false,
        audio_recording_id: audioRecordingId,
      };

      storeGlobalParametersSessionData(navigatorData);
      setGlobalParametersState(navigatorData);
    } catch (error) {
      handleError(error);
      notification.error("Failed to validate subdomain.");
      router.replace("/join/plans");
    }
  };

  return setState;
};
