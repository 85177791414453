import { storeGlobalParametersSessionData } from "./globalParametersSessionUtils";
import {
  GlobalParametersStateType,
  globalParametersAtom,
} from "./useGlobalParametersState";
import { useAtom } from "jotai";

/**
 * Sets any global parameter state in global parameters jotai atom.
 *
 * @returns setState()
 * @example
 * const setGlobalParameters = useSetGlobalParametersState();
 * setGlobalParameters({ parameter: "bla bla bla" });
 */

export const useSetGlobalParametersState = () => {
  const [existingParameters, setGlobalParametersState] =
    useAtom(globalParametersAtom);

  const setState = (parameters: GlobalParametersStateType) => {
    const newParameters = {
      ...existingParameters,
      ...parameters,
    };

    storeGlobalParametersSessionData(newParameters);
    setGlobalParametersState(newParameters);
  };

  return setState;
};
