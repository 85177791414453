import { atom, useAtom } from "jotai";

type Auth = {
  loading: boolean;
  authed: boolean;
  jwtTokenExpiresIn?: number;
};

export const authAtom = atom<Auth>({
  loading: true,
  authed: false,
  jwtTokenExpiresIn: undefined,
});

/**
 * Hook that returns the current authed state of the portal
 */
export const useAuth = () => useAtom(authAtom);
