import { atom, useAtom } from "jotai";
import { ProductListType } from "../krakenHelpers";

export const octopusProductsAtom = atom<{
  octopusProducts: ProductListType;
}>({
  octopusProducts: [],
});

export const useOctopusProducts = () => useAtom(octopusProductsAtom);
