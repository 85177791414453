import { useQueryProperties } from "@core/apiRequests";
import { ElectricityMeterPointServiceProvider } from "@core/apiRequests/graphql-global-types";
import { wholesaleAccountNumbers } from "@core/dashboard/wholesaleAccountNumbers";
import { isClientSide } from "@core/utils/isClientSide";
import { useEffect, useState } from "react";
import { isDevEnv } from "../env";
import { getPlan, usePortalAccountNumber } from "../portalUtils";
import { useGlobalParameterState } from "./../enrollment/globalParameters/useGlobalParametersState";

/**
 * hidden        - Hidden from everything. Not released for anything.
 *
 * dev           - Dev node environment only
 *
 * localStorage  - LocalStorage flag set to true
 *
 * allowList     - Only release for a specific list of matching identifiers
 *
 * denyList      - Release by default, except for a specific list of matching identifiers
 *
 * allowAccounts - Only release for specific account numbers
 *
 * denyAccounts  - Release for all except specified account numbers
 *
 * allowPlans    - Only release for customer accounts on specific plans
 *
 * denyPlans     - Release for all except customers on specific plans
 *
 * released      - Released for all of prod and dev
 */
export type Stage =
  | "hidden"
  | "dev"
  | "localStorage"
  | "allowList"
  | "denyList"
  | "allowAccounts"
  | "denyAccounts"
  | "allowPlans"
  | "denyPlans"
  | "released";

type Features = {
  [key: string]: {
    stage: Stage;
    permissionList?: string[];
  };
};

export type Feature =
  | "depositAgreements"
  | "enrollments"
  | "renewals"
  | "octoPrepay12"
  | "productCardsWithEV"
  | "signup"
  | "standaloneCheckout"
  | "thermostat"
  | "billReader"
  | "octoPlus12"
  | "octoEV12"
  | "octoThermostat12"
  | "octoPlus36"
  | "sonification"
  | "debtLedger"
  | "solarCost"
  | "realTimePriceCard"
  | "devAccounts"
  | "friendReferralCompetition"
  | "solarCompetition"
  | "autopayRequired"
  | "stripeFinancialConnections"
  | "octoThermostatPtcOnly"
  | "octoEVPtcOnly";

/**
 * Features object specifying the release stage of the feature and optional accounts for beta
 */
export const features: Features = {
  depositAgreements: {
    stage: "released",
  },
  enrollments: {
    stage: "released",
  },
  renewals: {
    stage: "released",
  },
  octoPrepay12: {
    stage: "released",
  },
  productCardsWithEV: {
    stage: "released", // change this to "denyList" when we need to turn it off, "released" when turning on
    permissionList: [
      ElectricityMeterPointServiceProvider.Lpl,
      ElectricityMeterPointServiceProvider.Tnmp,
    ],
  },
  signup: {
    stage: "released",
  },
  standaloneCheckout: {
    stage: "localStorage",
  },
  thermostat: {
    stage: "released",
  },
  billReader: {
    stage: "localStorage",
  },
  octoPlus12: {
    stage: "released",
  },
  octoEV12: {
    stage: "released",
  },
  octoThermostat12: {
    stage: "released",
  },
  octoThermostatPtcOnly: {
    stage: "hidden",
  },
  octoEVPtcOnly: {
    stage: "hidden",
  },
  octoPlus36: {
    stage: "hidden",
  },
  sonification: {
    stage: "dev",
  },
  debtLedger: {
    stage: "released",
  },
  solarCost: {
    stage: "released",
  },
  realTimePriceCard: {
    stage: "allowAccounts",
    permissionList: wholesaleAccountNumbers,
  },
  friendReferralCompetition: {
    stage: "hidden",
  },
  solarCompetition: {
    stage: "hidden",
  },
  autopayRequired: {
    stage: "localStorage",
  },
  stripeFinancialConnections: {
    stage: "released",
  },
};

/**
 * Function that returns a feature's active status
 * @param feature - The feature to flag against
 * @param customerAccountNumber - The customer's account number to check against for "beta" flag.
 * @returns `{ active }` - And object containing the active status of the feature
 * @example
 * const myFeature = useFeatureFlag('newFeature');
 * if (myFeature.active) { console.log('myFeature is active') }
 */
export const hasFeature = (
  feature: Feature,
  matchingValue?: string
): { active: boolean } => {
  const { stage, permissionList } = features[feature];
  switch (stage) {
    case "hidden":
      return { active: false };
    case "dev":
      return { active: isDevEnv() };
    case "localStorage":
      return {
        active: isClientSide()
          ? window.localStorage.getItem(feature) === "true"
          : false,
      };
    case "allowAccounts":
    case "allowPlans":
    case "allowList":
      return {
        active: Boolean(
          matchingValue && permissionList?.includes(matchingValue)
        ),
      };
    case "denyAccounts":
    case "denyPlans":
    case "denyList":
      return {
        active: Boolean(
          matchingValue && !permissionList?.includes(matchingValue)
        ),
      };

    case "released":
      return { active: true };
    default:
      return { active: false };
  }
};

/**
 * Hook that returns a feature's active status
 * @param feature - The feature to flag against
 * @returns `{ active }` - And object containing the active status and client side loaded of the feature
 * @example
 * const myFeature = useFeatureFlag('newFeature');
 * if (myFeature.active) { console.log('myFeature is active') }
 */
export const useFeatureFlag = (feature: Feature, matchingValueArg?: string) => {
  // Initial client side render should match initial server side render.
  // In the case of localstorage, the client side might not match server side
  // on the initial render. Client side loaded ensures that the feature flag has
  // been loaded client side. This can be used to render a feature only after both
  // the server and client side have loaded initially.
  const [clientSideLoaded, setClientSideLoaded] = useState(false);
  useEffect(() => {
    setClientSideLoaded(true);
  }, []);

  const stage = features[feature].stage;
  const isAccountsList = stage === "allowAccounts" || stage === "denyPlans";
  const isPlansList = stage === "allowPlans" || stage === "denyPlans";

  const accountNumber = usePortalAccountNumber();
  // only fetch plan code for current account if the stage is allow or deny plans
  const { data } = useQueryProperties({
    variables: {
      accountNumber,
      includeSubscriptionFees: true,
      includeFutureActiveAgreements: false,
    },
    skip: !isPlansList,
  });

  // use accountNumber as the matching value for allow or deny accounts
  // use plan code as the matching value for allow or deny plan
  // use provided matching value arg as default
  const matchingValue = (() => {
    if (isAccountsList) {
      return accountNumber;
    }
    if (isPlansList) {
      return getPlan(data)?.code;
    }
    return matchingValueArg;
  })();

  return {
    active: hasFeature(feature, matchingValue).active,
    clientSideLoaded,
  };
};

export const useEnrollmentsFeature = () => {
  const { active, clientSideLoaded } = useFeatureFlag("enrollments");
  const [{ navigatorState }] = useGlobalParameterState();
  return { active: Boolean(active || navigatorState), clientSideLoaded };
};
