import { LegalLinks } from "@core/legal";
import { RenewalAgreementsForm } from "@core/renew";
import { useCoralTheme } from "@krakentech/coral";
import { IconChevronDown } from "@krakentech/icons";
import { Box, Grid } from "@mui/material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Stack,
  Typography,
} from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";
import { FC, ReactNode } from "react";
import { useOctopusProduct } from "../../enrollment";
import { ProductListType } from "../../krakenHelpers";
import {
  ProductCardBullets,
  ProductCardMonthlyEstimate,
  ProductCardRateBreakdown,
  isPrepayProduct,
} from "../../product";
import {
  OctopusProductId,
  OctopusProductTranslation,
} from "../octopusProductConstants";

export type EnrollmentProductInformationProps = {
  title?: ReactNode;
  productId: OctopusProductId;
  hasTerminationFee?: boolean;
  minPrice?: number | string | null;
  withThermostat?: boolean;
  withEV?: boolean;
  rate?: ReactNode;
  lockedInDuration?: number;
  disclaimer?: {
    checkbox?: boolean;
    paragraph?: ReactNode;
  };
  serviceProviders?: ProductListType[0]["serviceProviders"];
  lang: OctopusProductTranslation;
  solarBuyback?: boolean;
  accordionFaq?: {
    summary: string | ReactNode;
    details: string | ReactNode;
  }[];
  "data-cy"?: string;
  renewal?: boolean;
  renewalStartDate?: Date | undefined;
  formattedRenewalStartDate?: string;
  formattedRenewalEndDate?: string;
  renewalQuoteCode?: string;
  onSelect?: VoidFunction;
};

export const EnrollmentProductInformation: FC<
  EnrollmentProductInformationProps
> = ({
  title,
  productId,
  minPrice,
  withThermostat = false,
  withEV = false,
  rate,
  serviceProviders = [],
  lang,
  accordionFaq,
  renewal = false,
  renewalStartDate = undefined,
  formattedRenewalStartDate = undefined,
  formattedRenewalEndDate = undefined,
  renewalQuoteCode = undefined,
  onSelect,
}) => {
  const { t: tProduct } = useTranslation(lang);
  const { t: tProductCard } = useTranslation("product/productCard");
  const theme = useCoralTheme();

  const octopusProduct = useOctopusProduct(productId);
  const isPrepay = isPrepayProduct(productId);
  const hideModalSubtext = [
    OctopusProductId.OCTO_PLUS_12M,
    OctopusProductId.OCTO_THERMOSTAT_12M,
    OctopusProductId.OCTO_EV_12M,
    OctopusProductId.RIVIAN_TIME_OF_USE,
    OctopusProductId.OCTO_BATTERY_12M,
  ].includes(productId);

  return (
    <Grid container data-cy="enrollment-product-information">
      <Grid item md={7}>
        <Box
          display="flex"
          alignItems="center"
          flexWrap="wrap"
          mb={3}
          gap={2}
          data-cy="enrollment-product-information-title"
        >
          {title}
        </Box>
        <Typography variant="body1" mt={3}>
          {!hideModalSubtext && <b>{tProduct("modal_subtext_1")}</b>}{" "}
          {tProduct("modal_subtext_2").replace(
            /PLAN_PLACEHOLDER/g,
            octopusProduct?.displayName || ""
          )}{" "}
        </Typography>
        <Box width="100%" mt={3} data-cy="enrollment-product-information-faqs">
          {accordionFaq?.map(({ details, summary }, index) => (
            <Accordion key={index}>
              <AccordionSummary
                expandIcon={
                  <IconChevronDown color={theme.color.tertiary.main} />
                }
              >
                <Typography variant="h6" color="tertiary">
                  {summary}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{details}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
        {rate || (
          <>
            <ProductCardMonthlyEstimate
              minPrice={minPrice}
              stackProps={{
                alignItems: "left",
                justifyContent: "left",
              }}
              monthlyRateProps={{ align: "left" }}
              monthlyEstimateTextProps={{ align: "left" }}
              offPeakPrice={octopusProduct?.prices.offPeakPrice}
            />{" "}
            <ProductCardRateBreakdown
              minPrice={minPrice}
              peakPrice={octopusProduct?.prices.peakPrice}
            />
          </>
        )}
        <Box data-cy="enrollment-product-information-bullets">
          <ProductCardBullets
            isPrepay={isPrepay}
            withEV={withEV}
            withThermostat={withThermostat}
            productId={productId}
          />
        </Box>

        {renewal && (
          <>
            <Box
              mt={2}
              sx={{
                backgroundColor: (theme) => theme.palette.secondary.main,
                borderRadius: 2,
              }}
            >
              <Typography py={1} align="center" variant="body1">
                Your new plan will start on{" "}
                <Typography component="span" fontWeight="bold">
                  {formattedRenewalStartDate}
                </Typography>
              </Typography>
            </Box>
            <Box
              mt={1}
              sx={{
                backgroundColor: (theme) => theme.palette.secondary.main,
                borderRadius: 2,
              }}
            >
              <Typography py={1} align="center" variant="body1">
                Your new plan will end on{" "}
                <Typography component="span" fontWeight="bold">
                  {formattedRenewalEndDate}
                </Typography>
              </Typography>
            </Box>
          </>
        )}

        {renewal ? (
          <RenewalAgreementsForm
            productId={productId}
            renewalQuoteCode={renewalQuoteCode}
            renewalStartDate={renewalStartDate}
            withEV={withEV}
            withThermostat={withThermostat}
          />
        ) : (
          <>
            <Button
              fullWidth
              sx={{ mt: 3 }}
              onClick={onSelect}
              data-cy="select-information-product-button"
            >
              {tProductCard("selectProduct")}
            </Button>
            <Stack mt={4} mb={2}>
              <Divider />
            </Stack>
            <Typography variant="h6" fontWeight="bold" color="tertiary">
              {tProductCard("planDocuments")}
            </Typography>
            <LegalLinks
              serviceProviders={serviceProviders}
              disclosures={{ allDisclosures: true }}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
};
