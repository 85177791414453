import { Typography } from "@krakentech/coral";
import { Box, Theme, useMediaQuery, useTheme } from "@mui/material";
import { Card, CardContent, Stack } from "@octopus-energy/coral-mui";
import { Circle } from "@octopus-energy/icons";
import useTranslation from "next-translate/useTranslation";
import { FC } from "react";

export type QuoteCardProps = {
  quoteData: {
    level: string;
    kwh: number;
    bedrooms: string;
    footage: string;
    residents: string;
  };
};

export const QuoteCard: FC<QuoteCardProps> = ({
  quoteData: { level, kwh, bedrooms, footage, residents },
}) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const { t } = useTranslation("enrollment/quote");
  const theme = useTheme();

  return (
    <Card
      data-cy="quote-card"
      color="paper"
      sx={{
        mt: 1.5,
        mb: 4,
        p: 2,
        pb: 0,
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        border: "none",
        background: `${theme.palette.secondary.dark}`,
      }}
    >
      <CardContent data-cy="quote-card-content">
        <Stack mb={4} data-cy="quote-approx-usage-text">
          <Typography
            variant="h5"
            attributes={{
              "data-cy": "quote-card-header",
            }}
          >
            {t("typically")}, {level.toLowerCase()} {t("consumptionHomes")}:
          </Typography>
        </Stack>
        <Box ml={2}>
          <Stack direction="row" alignItems="center">
            <Box pb={0.5}>
              <Circle sx={{ width: 10, height: 10, mt: 0.5 }} />
            </Box>
            <Box ml={2}>
              <Typography
                attributes={{
                  "data-cy": "quote-bedroom-desc",
                }}
              >
                {" "}
                {bedrooms}
              </Typography>
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center">
            <Box pb={0.5}>
              <Circle sx={{ width: 10, height: 10, mt: 0.5 }} />
            </Box>
            <Box ml={2}>
              <Typography
                attributes={{
                  "data-cy": "quote-occupant-desc",
                }}
              >
                {" "}
                {t("have")} {residents} {t("occupants")}
              </Typography>
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center">
            <Box pb={0.5}>
              <Circle sx={{ width: 10, height: 10, mt: 0.5 }} />
            </Box>
            <Box ml={2}>
              <Typography
                attributes={{
                  "data-cy": "quote-footage-desc",
                }}
              >
                {t("areAbout")} {footage} {t("sq_ft")}
              </Typography>
            </Box>
          </Stack>
        </Box>
        <Stack mt={8}>
          <Typography
            variant="h5"
            attributes={{
              "data-cy": "quote-approx-usage-text",
            }}
          >
            {t("approximateUsage")} {level.toLowerCase()} {t("household")}:
          </Typography>
        </Stack>
        <Box ml={2}>
          <Stack direction="row" alignItems="center" mt={4}>
            <Box pb={0.5}>
              <Circle sx={{ width: 10, height: 10, mt: 0.5 }} />
            </Box>
            <Box ml={2}>
              <Typography
                attributes={{
                  "data-cy": "quote-num-kwh-desc",
                }}
              >
                {kwh} {t("kwhElectricity")}
              </Typography>
            </Box>
          </Stack>
        </Box>
        <Stack mt={5}>
          <Typography
            variant={isMobile ? "caption" : "body2"}
            attributes={{
              "data-cy": "quote-good-to-know",
            }}
          >
            <b>
              {t("good_to_know")}
              {": "}
            </b>
            {t("use_real_usage")}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};
