var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"a09c254877f7ba6a4e70e4a0220f58d3e8a69e37"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.NEXT_PUBLIC_PROD_SERVER_SENTRY_DSN;

const init = () => {
  Sentry.init({
    // Configure SENTRY_DSN by default to the Test env. If it ends with sentry.io/5836977 it is the Production sentry env.
    // Getting 413 Payload Too Large, so clean up breadcrumbs
    dsn:
      SENTRY_DSN ||
      "https://01b08919535f4ac7bf9bed81c1b06658@o28796.ingest.sentry.io/5836971",
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category === "console") {
        return null;
      }
      return breadcrumb;
    },

    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.1,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
};

// Call the Sentry init function.
init();

export default init;
